import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { FaRegCopy, FaRegCheckCircle } from "react-icons/fa";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { IoTrashBin } from "react-icons/io5";
import { RiExternalLinkLine } from "react-icons/ri";
import axios from "axios";
import { AppContext } from "../App";
import NewProjectDialog from "../components/Dialogs/NewProjectDialog";
import ConfirmDialog from "../components/Dialogs/ConfirmDialog";

import { getTokenListByOwner } from "../utils/solana";
import { ellipsisAddress, isValidAddress } from "../utils/methods";
import RefreshIcon from "../components/Icons/RefreshButton";


export default function DashboardPage({ className }) {
  const {
    SERVER_URL,
    setLoadingPrompt,
    setOpenLoading,
    setUsers,
    projects,
    setProjects,
    setCurrentProject,
    assets,
    setAssets,
    loadAllProjects,
    setHolderMaxBuy,
    setSharkMaxBuy,
    setWhaleMaxBuy,
    setBotZombieAddress,
    setHolderWallets
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { connection } = useConnection();
  const { connected, publicKey } = useWallet();

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [confirmDialogTitle, setConfirmDialogTitle] = useState("");
  const [confirmDialogMessage, setConfirmDialogMessage] = useState("");
  const [confirmDialogAction, setConfirmDialogAction] = useState("");
  const [newProjectDialog, setNewProjectDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [copied, setCopied] = useState({});
  const [isProjectRefreshing, setIsProjectRefreshing] = useState(false);
  const [isAssetRefreshing, setIsAssetRefreshing] = useState(false);

  const copyToClipboard = async (key, text) => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(text);
      toast.success("Copied");
      setCopied({
        ...copied,
        [key]: true,
      });
      setTimeout(
        () =>
          setCopied({
            ...copied,
            [key]: false,
          }),
        2000
      );
    } else console.error("Clipboard not supported");
  };

  const handleConfirmDialogOK = async () => {
    setSelectedProject(null);
    setConfirmDialog(false);

    const accessToken = localStorage.getItem("access-token");
    if (confirmDialogAction === "delete-user") {
      setLoadingPrompt("Deleting user...");
      setOpenLoading(true);
      try {
        const { data } = await axios.post(
          `${SERVER_URL}/api/v1/user/delete`,
          {
            userId: selectedUser._id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "MW-USER-ID": accessToken,
            },
          }
        );
        if (data.users) setUsers(data.users);
        toast.success("User has been deleted successfully");
      } catch (err) {
        console.log(err);
        toast.warn("Failed to delete user");
      }
      setOpenLoading(false);
    } else if (confirmDialogAction === "activate-project") {
      setLoadingPrompt("Activating project...");
      setOpenLoading(true);
      try {
        const { data } = await axios.post(
          `${SERVER_URL}/api/v1/project/activate`,
          {
            projectId: selectedProject._id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "MW-USER-ID": accessToken,
            },
          }
        );
        if (data.projects) setProjects(data.projects);
        toast.success("Project has been activated successfully");
      } catch (err) {
        console.log(err);
        toast.warn("Failed to activate project");
      }
      setOpenLoading(false);
    } else if (confirmDialogAction === "delete-project") {
      setLoadingPrompt("Deleting project...");
      setOpenLoading(true);
      try {
        const { data } = await axios.post(
          `${SERVER_URL}/api/v1/project/delete`,
          {
            projectId: selectedProject._id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "MW-USER-ID": accessToken,
            },
          }
        );
        if (data.projects) {
          setProjects(data.projects);
        }
        toast.success("Project has been deleted successfully");
      } catch (err) {
        console.log(err);
        toast.warn("Failed to delete project");
      }
      setOpenLoading(false);
    }  
  };

  const handleActivateProject = (project) => {
    setSelectedProject(project);
    setConfirmDialogTitle("Activate Project");
    setConfirmDialogMessage(
      `Are you sure that you want to activate "${project.name}"?`
    );
    setConfirmDialogAction("activate-project");
    setConfirmDialog(true);
  };

  const handleDeleteProject = (project) => {
    setSelectedProject(project);
    setConfirmDialogTitle("Delete Project");
    setConfirmDialogMessage(
      `Are you sure that you want to delete "${project.name}"?`
    );
    setConfirmDialogAction("delete-project");
    setConfirmDialog(true);
  };

  const handleViewProject = async (project) => {
    setCurrentProject(project);
    setHolderMaxBuy(project.maxBuy[0]);
    setSharkMaxBuy(project.maxBuy[1]);
    setWhaleMaxBuy(project.maxBuy[2]);
    setBotZombieAddress(project.botZombie);
    setOpenLoading(false);
    if (project.status === "OPEN") navigate("/buy");
    else navigate("/sell");
  };

  const handleCreateNewProject = async (name) => {
    console.log("Creating new project...", name);
    try {
      const { data } = await axios.post(
        `${SERVER_URL}/api/v1/project/create`,
        {
          name: name,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "MW-USER-ID": localStorage.getItem("access-token"),
          },
        }
      );

      return {
        projectId: data.project._id,
        depositWallet: data.project.depositWallet.address,
        expireTime: data.expireTime,
      };
    } catch (err) {
      return { error: err };
    }
  };

  const handleCheckNewProject = async (projectId) => {
    console.log("Checking new project...", projectId);
    try {
      const { data } = await axios.post(
        `${SERVER_URL}/api/v1/project/check-status`,
        {
          projectId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "MW-USER-ID": localStorage.getItem("access-token"),
          },
        }
      );
      if (data.success) {
        return {
          activated: true,
        };
      } else {
        return {
          expired: data.expired,
          expireTime: data.expireTime,
        };
      }
    } catch (err) {
      return { error: err };
    }
  };

  const handleDoneCreatingNewProject = () => {
    console.log("================= handle done:");
    setNewProjectDialog(false);
    loadAllProjects();
  };

  const handleRefreshAssets = async () => {
    if (!connected) {
      setAssets([]);
      return;
    }

    setLoadingPrompt("Refreshing assets...");
    setOpenLoading(true);
    setIsAssetRefreshing(true);
    setTimeout(() => {
      setIsAssetRefreshing(false);
    }, 1000);
    try {
      const newTokenList = await getTokenListByOwner(
        connection,
        publicKey,
        true
      );
      setAssets(newTokenList);
    } catch (err) {
      console.log(err);
    }
    setOpenLoading(false);
  };

  return (
    <div className={`${className} flex flex-col text-white px-5`}>
      <ConfirmDialog
        isOpen={confirmDialog}
        title={confirmDialogTitle}
        message={confirmDialogMessage}
        onOK={handleConfirmDialogOK}
        onCancel={() => setConfirmDialog(false)}
      />
      <NewProjectDialog
        isOpen={newProjectDialog}
        createProject={handleCreateNewProject}
        checkProject={handleCheckNewProject}
        onDone={handleDoneCreatingNewProject}
        onCancel={() => setNewProjectDialog(false)}
        initialData={{ step: 0, projectName: "" }}
      />
      <div className="flex flex-col border-[1px] border-none rounded-t-[10px] mt-4">
        <div className="w-full h-auto px-5 py-2 bg-slate-title rounded-t-[10px] flex justify-between items-center">
          <div className="text-[20px] text-white font-medium font-poppins leading-normal">
            My Projects
          </div>
          <div>
            <button
              className="h-12 px-[25px] py-2 justify-center items-center gap-2.5 inline-flex border-[1px] border-white  rounded-full hover:bg-gradient-to-br active:scale-95 transition duration-100 ease-in-out transform focus:outline-none focus:ring-teal-300"
              onClick={() => setNewProjectDialog(true)}
            >
              <div className="font-normal leading-normal text-center text-white font-poppins bg-transparent">
                New Project
              </div>
            </button>

            <button
              className="h-12 px-[18px] py-2.5 rounded-full justify-center items-center gap-2.5 inline-flex border-none hover:bg-gradient-to-br active:scale-95 transition duration-100 ease-in-out transform focus:outline-none focus:ring-teal-300 ml-2"
              onClick={() => {
                loadAllProjects();
                setIsProjectRefreshing(true);
                setTimeout(() => {
                  setIsProjectRefreshing(false);
                }, 1000);
              }}
            >
              <RefreshIcon
                className={`
                  size-4 relative ${isProjectRefreshing ? "animate-spin" : ""}`}
              />
            </button>
          </div>
        </div>
        <div className="w-full min-h-28 bg-slate-900 bg-opacity-90   flex justify-between items-center px-5">
          <div className="relative flex flex-col w-full h-full my-5 overflow-x-hidden text-white bg-transparent bg-clip-border">
            <table className="w-full text-left">
              <thead className="">
                <tr className="">
                  <th className="w-10 p-4 border-b border-none bg-slate-title bg-opacity-30">
                    <p className="block font-sans antialiased font-normal leading-none text-center text-white">
                      No
                    </p>
                  </th>
                  <th className="p-4 border-b border-none bg-slate-title bg-opacity-30">
                    <p className="block font-sans antialiased font-normal leading-none text-center text-white">
                      Name
                    </p>
                  </th>
                  <th className="p-4 border-b border-none bg-slate-title bg-opacity-30">
                    <p className="block font-sans antialiased font-normal leading-none text-center text-white">
                      Status
                    </p>
                  </th>
                  <th className="p-4 border-b border-none bg-slate-title bg-opacity-30">
                    <p className="block font-sans antialiased font-normal leading-none text-center text-white">
                      Action
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody className="text-white text-base font-normal font-poppins leading-[24.93px]">
                {projects.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="p-4 border-b border-white border-opacity-30">
                        <p className="block font-sans antialiased font-normal leading-normal text-center text-white">
                          {index + 1}
                        </p>
                      </td>
                      <td className="p-4 border-b border-white border-opacity-30">
                        <p className="block font-sans antialiased font-normal leading-normal text-center text-white">
                          {item.name}
                        </p>
                      </td>
                      <td className="p-4 border-b border-white border-opacity-30">
                        <p className="block font-sans antialiased font-normal leading-normal text-center text-white">
                          {item.status}
                        </p>
                      </td>
                      <td className="p-4 border-b border-white border-opacity-30">
                        <div className="flex justify-center gap-2">
                          {item.status === "INIT" ||
                          item.status === "EXPIRED" ? (
                            <button
                              className="w-[39px] h-[25px] relative flex items-center justify-center cursor-pointer active:scale-95 transition duration-100 ease-in-out transform bg-slate-500 rounded-[41px]"
                              onClick={() => handleActivateProject(item)}
                            >
                              <FaRegCheckCircle className="w-4 h-4" />
                            </button>
                          ) : (
                            <button
                              className="w-[39px] h-[25px] relative flex items-center justify-center cursor-pointer active:scale-95 transition duration-100 ease-in-out transform bg-transparent rounded-[41px]"
                              onClick={() => handleViewProject(item)}
                            >
                              <MdOutlineRemoveRedEye className="w-5 h-5" />
                            </button>
                          )}
                          <button
                            className="w-[39px] h-[25px] relative flex items-center justify-center cursor-pointer active:scale-95 transition duration-100 ease-in-out transform bg-transparent rounded-[41px]"
                            onClick={() => handleDeleteProject(item)}
                          >
                            <IoTrashBin className="w-5 h-5" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {projects.length === 0 && (
              <div className="my-3 text-3xl text-center text-gray-700">
                No Project
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-10 border-[1px] border-none rounded-t-[15px]">
        <div className="w-full h-auto px-5 py-2 bg-slate-title rounded-t-[15px] flex justify-between items-center">
          <div className="text-[20px] text-white font-medium font-poppins leading-normal">
            My Assets
          </div>
          <button
            className="h-12 px-[18px] py-2.5 rounded-full justify-center items-center gap-2.5 inline-flex border-none hover:bg-gradient-to-br active:scale-95 transition duration-100 ease-in-out transform focus:outline-none focus:ring-teal-300"
            onClick={handleRefreshAssets}
          >
            <RefreshIcon
              className={`
                  size-4 relative ${isAssetRefreshing ? "animate-spin" : ""}`}
            />
          </button>
        </div>
        <div className="w-full min-h-28 bg-slate-900 bg-opacity-90   flex justify-between items-center px-5">
          <div className="relative flex flex-col w-full h-full my-5 overflow-x-hidden text-white bg-transparent bg-clip-border">
            <table className="w-full text-left">
              <thead className="">
                <tr className="">
                  <th className="w-10 p-4 border-b border-none bg-slate-title bg-opacity-30">
                    <p className="block font-sans antialiased font-normal leading-none text-center text-white">
                      No
                    </p>
                  </th>
                  <th className="p-4 border-b border-none bg-slate-title bg-opacity-30">
                    <p className="block font-sans antialiased font-normal leading-none text-white 2xl:ml-10">
                      Token
                    </p>
                  </th>
                  <th className="2xl:w-[23%] p-4 border-b border-none bg-slate-title bg-opacity-30">
                    <p className="block font-sans antialiased font-normal leading-none text-white 2xl:ml-4">
                      Account
                    </p>
                  </th>
                  <th className="2xl:w-[23%] p-4 border-b border-none bg-slate-title bg-opacity-30">
                    <p className="block font-sans antialiased font-normal leading-none text-white 2xl:ml-4">
                      Balance
                    </p>
                  </th>
                  <th className="2xl:w-[23%] p-4 border-b border-none bg-slate-title bg-opacity-30">
                    <p className="block font-sans antialiased font-normal leading-none text-white 2xl:ml-4">
                      Market ID
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody className="text-white text-base font-normal font-poppins leading-[24.93px]">
                {assets.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="p-4 border-b border-white border-opacity-30">
                        <p className="block font-sans antialiased font-normal leading-normal text-center text-white">
                          {index + 1}
                        </p>
                      </td>
                      <td className="p-4 border-b border-white border-opacity-30">
                        <div className="flex items-center gap-1 font-sans antialiased font-normal leading-normal text-teal-200 2xl:ml-10 min-w-8">
                          <p className="w-auto text-white bg-transparent border-none outline-none">
                            {item.name !== "" && item.symbol !== ""
                              ? `${item.name} (${item.symbol})`
                              : item.name !== ""
                              ? item.name
                              : ellipsisAddress(item.mint)}
                          </p>
                          {copied["token_" + index] ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-5 h-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          ) : (
                            <FaRegCopy
                              className="w-5 h-5 transition duration-100 ease-in-out transform cursor-pointer active:scale-95 text-baseColor"
                              onClick={() =>
                                copyToClipboard("token_" + index, item.mint)
                              }
                            />
                          )}
                          <a
                            href={`https://solscan.io/token/${item.mint}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <RiExternalLinkLine className="w-6 h-6 transition duration-100 ease-in-out transform cursor-pointer active:scale-95" />
                          </a>
                        </div>
                      </td>
                      <td className="p-4 border-b border-white border-opacity-30">
                        <div className="flex items-center gap-1 font-sans antialiased font-normal leading-normal text-teal-200 2xl:ml-4 min-w-8">
                          <p className="w-auto text-white bg-transparent border-none outline-none">
                            {ellipsisAddress(item.account)}
                          </p>
                          {copied["account_" + index] ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-5 h-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          ) : (
                            <FaRegCopy
                              className="w-5 h-5 transition duration-100 ease-in-out transform cursor-pointer active:scale-95 text-baseColor"
                              onClick={() =>
                                copyToClipboard(
                                  "account_" + index,
                                  item.account
                                )
                              }
                            />
                          )}
                        </div>
                      </td>
                      <td className="p-4 border-b border-white border-opacity-30">
                        <p className="block font-sans antialiased font-normal leading-normal text-white 2xl:ml-4">
                          {item.balance}
                        </p>
                      </td>
                      <td className="p-4 border-b border-white border-opacity-30">
                        <div className="flex items-center gap-1 font-sans antialiased font-normal leading-normal text-teal-200 2xl:ml-4 min-w-8">
                          {item.marketId ? (
                            <>
                              <p className="w-auto text-white bg-transparent border-none outline-none">
                                {ellipsisAddress(item.marketId)}
                              </p>
                              {copied["market_" + index] ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-5 h-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M5 13l4 4L19 7"
                                  />
                                </svg>
                              ) : (
                                <FaRegCopy
                                  className="w-5 h-5 transition duration-100 ease-in-out transform cursor-pointer active:scale-95 text-baseColor"
                                  onClick={() =>
                                    copyToClipboard(
                                      "market_" + index,
                                      item.marketId
                                    )
                                  }
                                />
                              )}
                            </>
                          ) : (
                            <p className="w-auto text-gray-600 bg-transparent border-none outline-none">
                              Not created
                            </p>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {projects.length === 0 && (
              <div className="my-3 text-3xl text-center text-gray-700">
                No Project
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
